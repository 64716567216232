import Contact from './Contact';

const ContactPage = () => {
  return (
    <>
        <Contact />
    </>
  )
}

export default ContactPage
