import { useState } from "react";
import { FaGithub } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [toggleState, setToggleState] = useState(false);

    let styles;

    if (toggleState) {
        styles = "flex";
    } else {
        styles = "hidden";
    }

    return (
        <nav className="flex items-center justify-between h-16 mx-12">
            <div className="flex justify-between w-2/3">
                <Link to="/">
                    <h4 className="md:text-3xl hover:underline">Dan Perkins</h4>
                </Link>
                <div
                    id="navbar-default"
                    className={`${styles} flex-col bg-primary-dark absolute top-16 left-0 w-full text-center [&>*]:py-3 md:relative md:justify-between md:[&>*]:py-0 md:top-0 md:w-2/3 md:text-left md:left-0 md:flex md:flex-row`}
                >
                    <div
                        className={`${styles} flex-row mx-auto w-14 justify-between md:hidden`}
                    >
                        <a
                            href="https://github.com/PerkinsDan"
                            className="hover:text-secondary-light"
                        >
                            <FaGithub size={25} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex-row justify-between hidden w-16 md:flex">
                <a
                    href="https://github.com/PerkinsDan"
                    className="hover:text-secondary-light"
                >
                    <FaGithub size={25} />
                </a>
            </div>
            <button
                onClick={() => setToggleState(!toggleState)}
                className="md:hidden"
            >
                <GiHamburgerMenu className="h-5" />
            </button>
        </nav>
    );
};

export default Navbar;
