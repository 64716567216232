const Contact = () => {
  return (
    <div className="my-40 text-center">
        <h1 className="text-4xl mb-12">Contact</h1>
        <p>Feel free to reach out to me on Twitter or Github!</p>
        <p>Email: dperkinsdev@gmail.com</p>
    </div>
  )
}

export default Contact
