import Tools from "./Tools";
import Projects from "./Projects";
import Contact from "./Contact";

const Home = () => {
    return (
        <>
            <Projects />
            <Tools />
            <Contact />
        </>
    );
};

export default Home;
