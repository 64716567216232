import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import ContactPage from "./components/ContactPage";
import { Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const App = () => {
  return (
    <div className="container mx-auto">
        <Navbar />
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="contact" element={<ContactPage />} />
        </Routes>
        <CookieConsent style={{ background: "#1B263B" }} buttonStyle={{ background: "#415A77", color: "white" }}>This website uses cookies</CookieConsent>
        <Footer />
    </div>
  )
}

export default App
