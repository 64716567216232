import { SiHtml5, SiCss3, SiJavascript, SiTypescript, SiReact, SiNextdotjs, SiMongodb, SiRust } from 'react-icons/si';

const Tools = () => {
  return (
    <div id="tools" className="grid grid-cols-2 md:grid-cols-4 gap-20 w-max mx-auto mt-52">
        <SiHtml5 size={125} />
        <SiCss3 size={125} />
        <SiJavascript size={125} />
        <SiTypescript size={125} />
        <SiReact size={125} />
        <SiNextdotjs size={125} />
        <SiMongodb size={125} />
        <SiRust size={125} />
    </div>
  )
}

export default Tools
